<template>
    <div class="Wisdomprocess">
        <div class="mprocess">
            <div class="wisd_top clearfix">
                <div class="_top_div _top_divs">
                    <div class="_top_title">
                        <span class="_tit_inblock">01</span>
                        <div class="_div_block">
                            <span class="_span1">重要伙伴</span> <br>
                            <span class="_span2">Important partner</span>
                        </div>
                    </div>
                    <div class="introduce">
                        <span>双创项目为了让商业模式有效运作所需的供应商与合作伙伴</span>
                    </div>
                    <div class="_top_content clearfix">
                        <div class="_top_c_left fl">
                            <p class="p_c_left_title">内部</p>
                            <div class="_top_c_left_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="110"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                        <div class="_top_c_left fr">
                            <p class="p_c_left_title">外部</p>
                            <div class="_top_c_left_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="110"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_top_div">
                    <div class="_top_div_one">
                        <div class="_top_title">
                            <span class="_tit_inblock">02</span>
                            <div class="_div_block">
                                <span class="_span1">产品服务</span> <br>
                                <span class="_span2">Product service</span>
                            </div>
                        </div>
                        <div class="introduce">
                            <span>双创项目得以成功运营所必须做的事情，基于其他几个模块来分析</span>
                        </div>
                        <div class="_top_content">
                            <div class="_cont_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="90"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="_top_div_two">
                        <div class="_top_title">
                            <span class="_tit_inblock">06</span>
                            <div class="_div_block">
                                <span class="_span1">核心资源</span> <br>
                                <span class="_span2">Core resources</span>
                            </div>
                        </div>
                        <div class="introduce">
                            <span>商业模式运转所必须的资源</span>
                        </div>
                        <div class="_top_content">
                            <div class="_cont_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="90"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_top_div _top_divs">
                    <div class="_top_title">
                        <span class="_tit_inblock">03</span>
                        <div class="_div_block">
                            <span class="_span1">价值主张</span> <br>
                            <span class="_span2">Value proposition</span>
                        </div>
                    </div>
                    <div class="introduce">
                        <span>双创项目为客户创造价值的产品或服务方式</span>
                    </div>
                    <div class="_top_content">
                        <div class="_cont_div">
                            <div class="_c_left_te">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="300"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_top_div">
                    <div class="_top_div_one">
                        <div class="_top_title">
                            <span class="_tit_inblock">04</span>
                            <div class="_div_block">
                                <span class="_span1">客户关系</span> <br>
                                <span class="_span2">Customer relations</span>
                            </div>
                        </div>
                        <div class="introduce">
                            <span>双创项目与客户建立关系以及如何维系关系</span>
                        </div>
                        <div class="_top_content">
                            <div class="_cont_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="90"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="_top_div_two">
                        <div class="_top_title">
                            <span class="_tit_inblock">07</span>
                            <div class="_div_block">
                                <span class="_span1">核心资源</span> <br>
                                <span class="_span2">Channel access</span>
                            </div>
                        </div>
                        <div class="introduce">
                            <span>双创项目服务流程中的客户接触点</span>
                        </div>
                        <div class="_top_content">
                            <div class="_cont_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="90"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_top_div _top_divs" style="margin-right:0">
                    <div class="_top_title">
                        <span class="_tit_inblock">05</span>
                        <div class="_div_block">
                            <span class="_span1">客户细分</span> <br>
                            <span class="_span2">Client subdivision</span>
                        </div>
                    </div>
                    <div class="introduce">
                        <span>双创项目所服务的客户群体分类</span>
                    </div>
                    <div class="_top_content">
                        <div class="bor-div">
                            <p class="con_p">影响因素</p>
                            <ul class="cong_ul">
                                <li>
                                    <span class="cong_span">决策者</span>
                                    <div class="cong_div">
                                        <el-input
                                            v-model="input" size="small">
                                        </el-input>
                                    </div>
                                </li>
                                <li>
                                    <span class="cong_span">影响者</span>
                                    <div class="cong_div">
                                        <el-input
                                            v-model="input" size="small">
                                        </el-input>
                                    </div>
                                </li>
                                <li>
                                    <span class="cong_span">推荐者</span>
                                    <div class="cong_div">
                                        <el-input
                                            v-model="input" size="small">
                                        </el-input>
                                    </div>
                                </li>
                                <li>
                                    <span class="cong_span">使用者</span>
                                    <div class="cong_div">
                                        <el-input
                                            v-model="input" size="small">
                                        </el-input>
                                    </div>
                                </li>
                            </ul>
                            <p class="con_p1">主要目标</p>
                        </div>
                        <div class="Classified">
                            <p class="ifi_tit">划分依据</p>
                            <el-radio-group v-model="radio">
                                <el-radio label="付费方式"></el-radio> <br>
                                <el-radio label="享受的价值主张类型"></el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wisd_bottom clearfix">
                <div class="_bott_left fl">
                    <div class="_top_title">
                        <span class="_tit_inblock">08</span>
                        <div class="_div_block">
                            <span class="_span1">成本结构</span> <br>
                            <span class="_span2">Cost structure</span>
                        </div>
                    </div>
                    <div class="introduce">
                        <span>商业模式运作下所引发的成本</span>
                    </div>
                    <div class="_top_content clearfix">
                        <div class="_top_c_left fl">
                            <p class="p_c_left_title">固定成本</p>
                            <div class="_top_c_left_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="130"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                        <div class="_top_c_left fr">
                            <p class="p_c_left_title">可变成本</p>
                            <div class="_top_c_left_div">
                                <div class="_c_left_te">
                                    <el-input
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="textarea"
                                        maxlength="130"
                                        show-word-limit>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_bott_left fr">
                    <div class="_top_title">
                        <span class="_tit_inblock">09</span>
                        <div class="_div_block">
                            <span class="_span1">收入来源</span> <br>
                            <span class="_span2">Source of income</span>
                        </div>
                    </div>
                    <div class="introduce">
                        <span>双创项目通过向客户提供价值主张而获得收入</span>
                    </div>
                    <div class="_top_content">
                        <div class="_cont_div">
                            <div class="_c_left_te">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="300"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            textarea:'',
            input:'',
            radio:'',
        }
    },
    methods: {
        
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../../assets/css/student/B/Wisdomprocess.css';
</style>
<style>
._c_left_te .el-textarea{
    width: 100%;
    height: 100%;
}
._c_left_te .el-textarea__inner{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    resize: none;
    padding: 5px 10px;
}
.cong_div .el-input__inner{
    background: #F4F4F4;
    border: 1px solid #9B9B9B;
    border-radius: 0;
    text-align: center;
}
.Classified .el-radio__input{
    margin-top: 3px;
}
.Classified .el-radio__input.is-checked+.el-radio__label{
    color: #2980B9;
}
.Classified .el-radio__input.is-checked .el-radio__inner{
    border-color: #2980B9;
    background: #2980B9;
}
.Classified .el-radio__label{
    font-size: 12px;
    padding-right: 5px;
}
.Classified .el-radio{
    width: 100%;
    overflow: hidden;
    margin-right: 0;
    line-height: 22px;
}
.Classified .el-radio__inner{
    border-radius: 2px;
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    border-top-color: rgb(220, 223, 230);
    border-right-color: rgb(220, 223, 230);
    border-bottom-color: rgb(220, 223, 230);
    border-left-color: rgb(220, 223, 230);
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #fff;
    z-index: 1;
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
}
.Classified .el-radio__inner::after{
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
    border-radius: 0;
    background: transparent;
}
.Classified .el-radio__input.is-checked .el-radio__inner:after {
    transform: rotate(45deg) scaleY(1);
}
</style>